import { useLazyQuery } from '@apollo/client'
import { Button, Container, Grid, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { GET_PRODUCT_CONTAINERS } from '../../operations/queries'
import CategoryProductCard from '../DesktopElements/CategoryPageElements/CategoryProductCard'
import DynamicCategoryProductCard from '../DesktopElements/CategoryPageElements/DynamicCategoryProductCard'
import { useAppContext } from '../../context/appContext'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: theme.spacing(10),
        [theme.breakpoints.down('lg')]: {
          marginTop: theme.spacing(1),
        },
      },
      gridItem: {
        display: 'inherit',
        height: 'fit-content',
      },
      productCard: {
        padding: 10,
      },
      linearProgress: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
      },
      linearProgressColor: {
        backgroundColor: theme.palette.text.secondary,
      },
      loadMoreBtn: {
        marginTop: theme.spacing(6),
        width: '10rem',

        [theme.breakpoints.down('lg')]: {
          marginTop: theme.spacing(1),
        },
      },
    }),
  { name: 'MuiProductListComponent' },
)

const ProductList = ({ productContainersData, locale }) => {
  const appContext = useAppContext()
  const b2bToken = appContext.b2bToken

  const classes = useStyles() as any
  const { sortedProductContainers, lastProductId, hasNextPage, filters, take } =
    productContainersData
  const [
    fetchProducts,
    { data: dynamicData, loading, error, called, fetchMore },
  ] = useLazyQuery(GET_PRODUCT_CONTAINERS, {
    notifyOnNetworkStatusChange: true,
  })
  const initialProductsList = b2bToken?.b2b_only_selected_products
    ? []
    : sortedProductContainers
  const [productContainers, setProductContainers] = useState([
    ...sortedProductContainers,
  ])

  const { t } = useTranslation(['common'])
  const loadMoreText = t('load-more')

  const whereFilter = b2bToken?.b2b_only_selected_products
    ? {
        customers: {
          some: {
            customerId: {
              equals: b2bToken.id,
            },
          },
        },
      }
    : null

  useEffect(() => {
    if (b2bToken?.b2b_only_selected_products) {
      fetchProducts({
        variables: {
          where: whereFilter,
          orderBy: filters.orderBy,
          take,
          loadMore: true,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (called && !loading) {
      setProductContainers((prev) => [
        ...initialProductsList,
        ...dynamicData.sortedProductContainersData.sortedProductContainers,
      ])
    }

    return
  }, [dynamicData])

  const cursor = {
    id: dynamicData?.sortedProductContainersData.lastProductId || lastProductId,
  }

  const hasNextPageDynamic = dynamicData
    ? dynamicData?.sortedProductContainersData.hasNextPage
    : hasNextPage

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={2}>
        {productContainers.map((productContainer, index) => {
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              key={productContainer.id}
              className={classes.gridItem}
            >
              {productContainer.type ? (
                <CategoryProductCard
                  productContainer={productContainer}
                  locale={locale}
                />
              ) : (
                <DynamicCategoryProductCard
                  productContainerID={productContainer.id}
                />
              )}
            </Grid>
          )
        })}
      </Grid>
      {/* <Grid container spacing={2}>
          {called &&
            !loading &&
            dynamicData.sortedProductContainersData.sortedProductContainers.map(
              (productContainer, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={3}
                    key={productContainer.id}
                    className={classes.gridItem}
                  >
                    <DynamicCategoryProductCard
                      productContainerID={productContainer.id}
                    />
                  </Grid>
                );
              }
            )}
        </Grid> */}

      <Grid container spacing={2}>
        {loading && (
          <LinearProgress
            classes={{
              colorSecondary: classes.linearProgressColor,
              root: classes.linearProgress,
            }}
          />
        )}
      </Grid>

      {hasNextPageDynamic && (
        <Button
          variant="contained"
          onClick={() => {
            if (!called) {
              fetchProducts({
                variables: {
                  where: whereFilter,
                  orderBy: filters.orderBy,
                  take,
                  cursor,
                  loadMore: true,
                },
              })
              return
            } else {
              fetchMore({
                variables: {
                  where: whereFilter,
                  orderBy: filters.orderBy,
                  take,
                  cursor,
                  loadMore: true,
                },
              })
            }
          }}
          className={classes.loadMoreBtn}
        >
          {loadMoreText}
        </Button>
      )}
    </Container>
  )
}

export default ProductList
