import { Divider, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { LocalShipping } from '@mui/icons-material'
import 'moment/locale/et'
// Languages...
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/ru'
import React from 'react'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      row: {
        // cursor: "pointer",
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
      },

      rowContentContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
      },

      chevronRight: {
        cursor: 'pointer',
      },

      carIcon: {
        fontSize: '30px',
        color: theme.palette.text.secondary,
        marginRight: '15px',
      },
    }),
  { name: 'MuiShippingInformationBox' },
)

const ShippingInformationBox = ({ shipping_method, locale }) => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  const free_shipping_string = t('free_shipping_string')
  const shipping_price_from_string = t('shipping_price_from_string')

  // console.log("shipping_method ->", shipping_method);

  const shippingPrice = shipping_method
    ? shipping_method.price_for_box > 0
      ? shipping_method.price_for_box
      : 0
    : 0
  const shippingPriceConverted = priceFormatter(shippingPrice)

  const isFreeShipping = shipping_method
    ? shipping_method.price_for_box === 0
      ? true
      : false
    : true

  return (
    <>
      <div
        className={classes.row}
        // onClick={() => {
        //   variantsDrawerStateVar({
        //     open: true,
        //     component: "DELIVERY_INFORMATION_FORM",
        //   });
        // }}
      >
        <LocalShipping className={classes.carIcon} />

        <div className={classes.rowContentContainer}>
          {isFreeShipping ? (
            <Typography component="p" variant="body2">
              {free_shipping_string}
            </Typography>
          ) : (
            <>
              <Typography
                component="p"
                variant="body1"
                style={{ marginRight: '5px' }}
              >
                {shipping_price_from_string}
              </Typography>
              <Typography component="p" variant="body2">
                {priceFormatter(shippingPrice)}
              </Typography>
            </>
          )}
        </div>

        {/* <ChevronRightIcon className={classes.chevronRight} /> */}
      </div>
      <Divider light />
    </>
  )
}

export default ShippingInformationBox
