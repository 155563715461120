import { useQuery, useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../../../context/appContext'
import TopAdvancedProductPage from '../TopAdvancedProductPage'
import { parse } from 'next-useragent'
import AdvancedSofa from './AdvancedSofa'
import AdvancedProductWithFabricsAndAdditionalComponents from './AdvancedProductWithFabricsAndAdditionalComponents'
import CustomSection from '../../Utils/CustomSection'
import { ProductSlider } from '../../Utils'
import { makeStyles } from '@mui/styles'
import { recentlyViewedVar } from '../../../../store/reactiveVars'
import { useEmbedContext } from '../../../../context/embedConfiguratorContext'
import { GET_ADDITIONAL_PRODUCT_DATA } from '../../../../operations/queries'
import { getProfileIndexFromLanguage } from '../../../../utilityFunctions/getProfile'
import { addToRecentlyViewed } from '../../../../operations/clientMutations/recentlyViewedClient'
import Box from '@mui/material/Box'
import ContentBlock from '../ContentBlock'
import { Theme } from '@emotion/react'

export default function AdvancedProductPage({ productPermalink, productData }) {
  const { t } = useTranslation(['common'])
  const recentlyViewedString = t('recently-viewed-carousel-header')
  const moreProductsString = t('more-products')
  const recentlyViewed = useReactiveVar(recentlyViewedVar)
  const { locale } = useAppContext()

  // -- UA in AppContext is deprecated...
  const { queryParams } = useEmbedContext()
  // const userAgent = appContext ? appContext.userAgent : null
  const userAgent = null
  // const isSafari = userAgent?.isSafari && userAgent?.isDesktop ? true : false;

  const [isSafari, setIsSafari] = useState(
    userAgent?.isSafari && userAgent?.isDesktop ? true : false,
  )

  // --- After moving to getStaticProps  we need to load userAgent locally
  useEffect(() => {
    // --- trying to get user agent
    const ua = parse(window.navigator.userAgent)
    setIsSafari(ua?.isSafari && ua?.isDesktop ? true : false)
  }, [])

  const currentProductID = productData.id ?? null

  // We get all categories of the prouct
  const currentProductCategories =
    productData.categories.map((item) => item.id) ?? []

  // We check for primary category
  const currentProductPrimaryCategory = productData.primary_category?.id ?? null

  // We form a list of primary category, if it doesnt exist then from all categories
  const categoriesListForQuery = currentProductPrimaryCategory
    ? [currentProductPrimaryCategory]
    : currentProductCategories

  const {
    loading: sameCategoryProductsLoading,
    data: sameCategoryProductsData,
  } = useQuery(GET_ADDITIONAL_PRODUCT_DATA, {
    variables: {
      language: locale,
      productCategories: categoriesListForQuery,
    },
  })

  const sameCategoryProducts =
    sameCategoryProductsData?.findManyProductContainer ?? []

  const modifiedRecentlyViewed = recentlyViewed
    .slice()
    .filter((item) => item.id != currentProductID)

  // ======================== DATA FROM STATIC PROPS
  const product = productData.advanced_product.advanced_product_profile

  // ====== PRODUCT STYLE ========
  const productStyle = productData.style ?? null
  const styleProfileIndex = productStyle
    ? getProfileIndexFromLanguage(productStyle.style_profiles, locale)
    : null

  const stylePermalink =
    styleProfileIndex != null && styleProfileIndex > -1
      ? productStyle.style_profiles[styleProfileIndex].meta_information
          .permalink
      : null
  const styleSlug = t('style-slug')

  // ====== PRODUCT COLLECTION ========
  const productCollection = productData.collection ?? null
  const collectionProfileIndex = productCollection
    ? getProfileIndexFromLanguage(productCollection.collection_profiles, locale)
    : null

  const collectionPermalink =
    collectionProfileIndex != null && collectionProfileIndex > -1
      ? productCollection.collection_profiles[collectionProfileIndex]
          .meta_information.permalink
      : null
  const collectionSlug = t('collection-slug')

  //* I run a useEffect everytime the productPermalink changes
  //* then add the product to the recentlyViewedVar.
  //* But since I don't want to show the current product in the recently viewed carousel
  //* so I filter out all the products that are not that one
  //* plus I only want the first 15, so I make sure the index is lower than 15
  //* set a state variable to those products, which from the JSX will render.
  useEffect(() => {
    // ====== mutation to add the constructed item to the recentlyViewed field client side======
    addToRecentlyViewed({
      id: productData.id,
      name: productData.advanced_product.advanced_product_profile?.name,
      price: productData.advanced_product.price,
      image: productData.advanced_product.images[0]?.src,
      permalink: productPermalink,
      product_type: productData.type,
      dispatchInformation: productData.dispatch_information,
      discount: productData.discount,
    })
  }, [productPermalink, productData])

  const contentBlocks = productData.content_blocks

  queryParams
  // check if property exist in queryParams and use its value or use default value
  const getEmbedProperty = (queryParams, property, defaultValue) => {
    if (property in queryParams) {
      return queryParams[property]
    }

    return defaultValue
  }

  const showContentBlocks = getEmbedProperty(
    queryParams,
    'showContentBlocks',
    true,
  )
  const showProductLists = getEmbedProperty(
    queryParams,
    'showProductLists',
    true,
  )
  const showProductMainBlock = getEmbedProperty(
    queryParams,
    'showMainBlock',
    true,
  )
  const showFabrics = getEmbedProperty(queryParams, 'showFabrics', true)
  const showComponents = getEmbedProperty(queryParams, 'showComponents', true)
  const showSofaShapes = getEmbedProperty(queryParams, 'showSofaShapes', true)
  const showConfiguration = getEmbedProperty(
    queryParams,
    'showConfiguration',
    true,
  )
  const showAddToCartButton = getEmbedProperty(
    queryParams,
    'showAddToCartButton',
    true,
  )

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      maxWidth: 1920,
      margin: '0 auto',
    },
  }))
  const classes = useStyles() as any

  return (
    <div>
      {showProductMainBlock && (
        <TopAdvancedProductPage
          productPermalink={productPermalink}
          product={productData}
          isSafari={isSafari}
        />
      )}

      {contentBlocks && showContentBlocks && (
        <Box sx={{ margin: '50px 0' }}>
          {contentBlocks.map((contentBlock, i) => (
            <ContentBlock key={i} data={contentBlock} index={i} />
          ))}
        </Box>
      )}

      {/* ====== ADVANCED PRODUCT CONFIGURABLE OPTIONS SECTION ========= */}
      {/* FABRICS */}

      <div style={{ overflow: 'hidden' }}>
        <AdvancedSofa
          advancedProduct={productData}
          productPermalink={productPermalink}
          showFabrics={showFabrics}
          showComponents={showComponents}
          showSofaShapes={showSofaShapes}
          showConfiguration={showConfiguration}
          showAddToCartButton={showAddToCartButton}
        />

        <AdvancedProductWithFabricsAndAdditionalComponents
          advancedProduct={productData}
          productPermalink={productPermalink}
          showFabrics={showFabrics}
          showComponents={showComponents}
          showConfiguration={showConfiguration}
          showAddToCartButton={showAddToCartButton}
        />
      </div>

      {/* ====== ^^^^^ (above) ADVANCED PRODUCT CONFIGURABLE OPTIONS SECTION ^^^^^ (above) ========= */}

      {/* //TODO: there will be a component for manufacturer the will have a map on the right half with manufacturer coordinates and text on the left half */}
      {/* COLLECTION SECTION - ALIGNED Left */}
      {productCollection &&
        showProductLists &&
        collectionProfileIndex !== null &&
        collectionProfileIndex !== undefined && (
          <CustomSection
            alignImage="left"
            locale={locale}
            product_containers={productCollection.product_containers}
            sectionName={
              productCollection.collection_profiles[collectionProfileIndex].name
            }
            sectionDescription={
              productCollection.collection_profiles[collectionProfileIndex]
                .description
            }
            sectionImage={productCollection.main_image?.src}
            dynamic={true} // Makes use of Dynamic product card instead of static
            permalink={collectionPermalink}
            slug={collectionSlug}
          />
        )}
      {/* STYLE SECTION - ALIGNED RIGHT */}
      {productStyle &&
        showProductLists &&
        styleProfileIndex !== null &&
        styleProfileIndex !== undefined && (
          <CustomSection
            alignImage="right"
            locale={locale}
            product_containers={productStyle.product_containers}
            sectionName={productStyle.style_profiles[styleProfileIndex].name}
            sectionDescription={
              productStyle.style_profiles[styleProfileIndex].description
            }
            sectionImage={productStyle.main_image?.src}
            dynamic={true} // Makes use of Dynamic product card instead of static
            permalink={stylePermalink}
            slug={styleSlug}
          />
        )}
      {/* <Section fullWidth disablePadding>
          <Contact />
        </Section> */}
      {sameCategoryProducts.length > 1 && showProductLists && (
        <ProductSlider
          product={productData}
          sliderTitle={moreProductsString}
          items={sameCategoryProducts}
          variant="h5"
        />
      )}

      {recentlyViewed.length > 1 && showProductLists && (
        <ProductSlider
          product={productData}
          sliderTitle={recentlyViewedString}
          items={modifiedRecentlyViewed}
          variant="h5"
        />
      )}
    </div>
  )
}
