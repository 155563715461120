import { Divider, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { LocalShipping } from '@mui/icons-material'
import 'moment/locale/et'
// Languages...
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/ru'
import React from 'react'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { useTranslation } from 'next-i18next'

import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      row: {
        cursor: 'pointer',
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
      },

      rowContentContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
      },

      chevronRight: {
        cursor: 'pointer',
      },

      carIcon: {
        fontSize: '30px',
        color: theme.palette.text.secondary,
        marginRight: '15px',
      },
    }),
  { name: 'MuiD3modelDownloadBox' },
)

const D3modelDownloadBox = ({ d3ModelLink, locale }) => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  let d3_model_string = t('3d-model')

  if (d3_model_string.endsWith(':')) {
    d3_model_string = d3_model_string.slice(0, -1)
  }

  // console.log('d3ModelLink :>> ', d3ModelLink)

  return (
    <>
      <div
        className={classes.row}
        onClick={() => {
          window.open(d3ModelLink, '_blank')
        }}
      >
        <div className={classes.rowContentContainer}>
          <ThreeDRotationIcon className={classes.carIcon} />

          <Typography
            component="p"
            variant="body1"
            style={{ marginRight: '5px' }}
          >
            {d3_model_string}
          </Typography>
        </div>

        <ChevronRightIcon className={classes.chevronRight} />
      </div>
      <Divider light />
    </>
  )
}

export default D3modelDownloadBox
