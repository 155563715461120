import { Grid, Theme } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Media } from '../../../../../lib/artsyFresnel/Media'
import { useAppContext } from '../../../../context/appContext'
import FabricsSelect from './FabricsSelect'
import SofaShapeSelect from './SofaShapeSelect'
import dynamic from 'next/dynamic'
import StickyPricePreview from './StickyPricePreview'
import { useInView } from 'react-intersection-observer'
import AdditionalComponentGroup from './AdditionalComponentGroup'
import { useReactiveVar } from '@apollo/client'
import {
  advancedProductVarsResetedVar,
  selectedAdditionalComponentsVar,
} from '../../../../store/reactiveVars'

const SofaConfigurationPreview = dynamic(
  () => import('./SofaConfigurationPreview'),
  {
    ssr: false,
  },
)

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      card: {
        position: 'relative',
      },
      cardContent: {},
    }),
  { name: 'MuiSizeDrawerCardComponent' },
)

export default function AdvancedSofa({
  advancedProduct,
  productPermalink,
  showSofaShapes,
  showComponents,
  showConfiguration,
  showFabrics,
  showAddToCartButton,
}) {
  const classes = useStyles() as any
  const { locale } = useRouter()

  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const chooseFabricText = t('choose-fabric-text')
  const chooseSofaShapeText = t('choose-sofa-shape-text')
  const reviewYourConfigurationText = t('review-your-configuration-text')

  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()

  // return (
  //   <Card elevation={0} square={true} className={classes.card}>
  //     <CardContent className={classes.cardContent}>Fabrics...</CardContent>
  //   </Card>
  // )

  // ==== We now need to map all the shapes to extract available fabrics ===
  let availablefabricGroups = [] as any
  for (const priceCategory of advancedProduct?.manufacturer
    ?.fabric_price_category) {
    for (const group of priceCategory.fabric_groups) {
      // Check if not already addded...
      if (availablefabricGroups.filter((e) => e.id === group.id).length > 0) {
        /* vendors contains the element we're looking for */
      } else {
        // === We determine the groups price here...
        const groupNumber = priceCategory.group_number ?? 0 // CAT 1 / CAT 2 etc...

        let priceMultiplier = 1
        // How many EUR ($) sings do we show?
        if (groupNumber > 2 && groupNumber < 5) {
          priceMultiplier = 2
        } else if (groupNumber > 4 && groupNumber < 7) {
          priceMultiplier = 3
        } else if (groupNumber > 6 && groupNumber < 10) {
          priceMultiplier = 4
        } else if (groupNumber > 9) {
          priceMultiplier = 5
        }

        const object = {
          priceMultiplier,
          form_price_fabric_category: priceCategory,
          ...group,
        }
        availablefabricGroups.push(object)
      }
    }
  }

  // We arange them here by Category number -> CAT 1 first, then the rest...
  const availablefabricGroupsSorted = availablefabricGroups.sort((a, b) =>
    // @ts-ignore
    a.form_price_fabric_category.group_number >
    // @ts-ignore
    b.form_price_fabric_category.group_number
      ? 1
      : -1,
  )
  const sofaForms = advancedProduct?.advanced_product?.sofa_forms ?? []

  const discount = advancedProduct.discount ?? null

  // console.log('advancedProduct inside Fabrics select :>> ', advancedProduct)

  // ===== HANDLEWHIC BLOCK IS IN VIEW
  const [viewingStep, setViewingStep] = useState(0)
  const [finalStep, setFinalStep] = useState(false)

  const [refFabric, inViewFabric, entryFabric] = useInView({
    threshold: 0.4,
  })
  const [refSofaShape, inViewSofaShape, entrySofaShape] = useInView({
    threshold: 0.4,
  })

  const [
    refAdditionalComponents,
    inViewAdditionalComponents,
    entryAdditionalComponents,
  ] = useInView({
    threshold: 0.4,
  })

  const [refPreview, inViewPreview, entryPreview] = useInView({
    threshold: 0.4,
  })

  useEffect(() => {
    let number = 0
    if (inViewFabric) {
      number = 1
    }
    if (inViewSofaShape) {
      number = 2
    }
    if (inViewAdditionalComponents) {
      number = 2
    }
    if (inViewPreview) {
      number = 3
    }
    setViewingStep(number)

    if (number === 3) {
      setFinalStep(true)
    } else {
      setFinalStep(false)
    }
  }, [inViewFabric, inViewSofaShape, inViewPreview, inViewAdditionalComponents])

  const steps = [
    chooseFabricText,
    chooseSofaShapeText,
    reviewYourConfigurationText,
  ]

  // Additional components data FACTORING =====================
  let additionalComponentGroups =
    advancedProduct.advanced_product.additional_component_groups
  //----
  // We need to follow this, as Var resets happens strangely after render...
  const advancedProductVarsReseted = useReactiveVar(
    advancedProductVarsResetedVar,
  )

  //=== We need to select the first item of each group
  useEffect(() => {
    // console.log('Selecting component groups')
    if (additionalComponentGroups) {
      let selectedComponents = [] as any
      for (const group of additionalComponentGroups) {
        //--
        let firstComponent = group.additional_components[0]
        if (firstComponent) {
          selectedComponents.push(firstComponent)
        }
      }
      selectedAdditionalComponentsVar(selectedComponents)
    }
  }, [advancedProductVarsReseted])

  //==== END ADDITIONAL COMPONENTS

  if (advancedProduct?.advanced_product?.advanced_product_type === 'SOFA') {
    return (
      <Grid container justifyContent="space-evenly" spacing={1}>
        <Grid id="fabricSelect" ref={refFabric} item xs={12} lg={12}>
          {showFabrics && (
            <FabricsSelect fabricGroups={availablefabricGroupsSorted} />
          )}
        </Grid>

        <Grid ref={refSofaShape} item xs={12} lg={12}>
          {showSofaShapes && (
            <SofaShapeSelect discount={discount} sofaForms={sofaForms} />
          )}
        </Grid>

        <Grid ref={refAdditionalComponents} item xs={12} lg={12}>
          {showComponents &&
            additionalComponentGroups?.map((group) => (
              <AdditionalComponentGroup
                key={group.id}
                componentGroup={group}
                locale={locale}
              />
            ))}
        </Grid>

        <Grid ref={refPreview} item xs={12} lg={12}>
          {showConfiguration && (
            <SofaConfigurationPreview
              discount={discount}
              advancedProduct={advancedProduct}
            />
          )}
        </Grid>

        {showAddToCartButton && (
          <StickyPricePreview
            productPermalink={productPermalink}
            advancedProduct={advancedProduct}
            discount={discount}
            steps={steps}
            viewingStep={viewingStep}
            finalStep={finalStep}
          />
        )}
      </Grid>
    )
  } else {
    return <span></span>
  }
}
