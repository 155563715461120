import { Divider, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import 'moment/locale/et'
// Languages...
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/ru'
import React from 'react'
import { variantsDrawerStateVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      row: {
        cursor: 'pointer',
        display: 'flex',
        // justifyContent: "space-between",
        // alignItems: "center",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
        // padding: theme.spacing(1.5, 2),
        // backgroundColor: "#e7edf0",
        // [theme.breakpoints.down("md")]: {
        //   marginBottom: theme.spacing(4),
        // },
      },

      rowContentContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
      },

      chevronRight: {
        cursor: 'pointer',
      },
    }),
  { name: 'MuiReturnsInformationBox' },
)

const ReturnsInformationBox = ({ returns_policy, locale }) => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  const returns_policy_string = t('returns_policy_string')

  const policyProfileIndex: number = getProfileIndexFromLanguage(
    returns_policy,
    locale,
  )

  const returnsPolicyExtractedLanguage =
    policyProfileIndex != null ? returns_policy[policyProfileIndex] : null

  const returnsPolicyName = returnsPolicyExtractedLanguage
    ? returnsPolicyExtractedLanguage.name
    : ''
  const returnsPolicyContent = returnsPolicyExtractedLanguage
    ? returnsPolicyExtractedLanguage.content
    : null

  return (
    <>
      <div
        className={classes.row}
        onClick={() => {
          variantsDrawerStateVar({
            open: true,
            data: returnsPolicyContent,
            component: 'RETURNS_INFORMATION_FORM',
          })
        }}
      >
        <div className={classes.rowContentContainer}>
          <Typography
            component="p"
            variant="body1"
            style={{ marginRight: '5px' }}
          >
            {returns_policy_string}
          </Typography>
          {returnsPolicyExtractedLanguage && (
            <Typography component="p" variant="body2">
              - {returnsPolicyName}
            </Typography>
          )}
        </div>

        <ChevronRightIcon className={classes.chevronRight} />
      </div>
      <Divider light />
    </>
  )
}

export default ReturnsInformationBox
