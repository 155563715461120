import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { removeFromCart } from '../../../operations/clientMutations/cartClient'
import { GET_PRODUCT_IN_CART } from '../../../operations/queries/headerQueries'
import { cartItemsVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { QuantitySelector } from '../../DesktopElements/Utils'
import dynamic from 'next/dynamic'
import { parseSofaCombination } from '../../../utilityFunctions/parseSofaCombination'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { imageFallback } from '../../../constants/Images'
import AdvancedProductCartPagePreview from '../../DesktopElements/CartPageElements/AdvancedProductCartPagePreview'
import { ProductPrice } from '../../UtilityComponents/price'
import { getComponentGroupName } from '../../../utilityFunctions/getComponentGroupName'

const SofaCombinationCartPagePreview = dynamic(
  () =>
    import(
      '../../DesktopElements/CartPageElements/SofaCombinationCartPagePreview'
    ),
  {
    ssr: false,
  },
)

const useStyles = makeStyles(
  (theme) => ({
    gridItem: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      borderBottom: '1px solid #d3d3d3',
    },
    media: {
      minWidth: '4rem',
      height: '5rem',
      backgroundSize: 'contain',
      alignSelf: 'center',
      margin: theme.spacing(2),
      [theme.breakpoints.only('xs')]: {
        margin: theme.spacing(1),
      },
    },
    productInfoContainer: {
      width: '100%',
      padding: theme.spacing(2, 2, 2, 0),
    },
    pricesContainer: {
      display: 'flex',
      paddingTop: theme.spacing(2),
    },
    price: {
      marginRight: theme.spacing(2),
    },
    removeButtonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },
    discountedPrice: {
      marginRight: theme.spacing(2),
      textDecoration: 'line-through',
    },
  }),
  { name: 'MuiResponsiveCartPageItemComponent' },
)

export default function ResponsiveCartPageItem({ cartItem }) {
  if (cartItem.product_type === 'SIMPLE_PRODUCT') {
    return <ResponsiveCartPageItemSingleProduct cartItem={cartItem} />
  } else if (cartItem.product_type === 'ADVANCED_PRODUCT') {
    return <ResponsiveCartPageItemAdvancedProduct cartItem={cartItem} />
  } else {
    // unhandlend entry
    return <p>Unhandled product type</p>
  }
}

const ResponsiveCartPageItemSingleProduct = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItemString = t('remove-cartPage-item')
  const mobileUnitPriceString = t('mobile-unit-price')
  const classes = useStyles() as any
  const { locale } = useRouter()
  const client = useApolloClient()
  // const cartItems = useReactiveVar(cartItemsVar)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discounted, setDiscounted] = useState(false)

  //* =================================================================================
  //* ==================  query to get latest cartItems info ======================
  //* =================================================================================
  const { data, loading, error, called } = useQuery(GET_PRODUCT_IN_CART, {
    variables: {
      itemID: cartItem.id,
    },
    //On completed we will have the data and we want to update
    //all the static data in the reactive var and localstorage
    onCompleted: (data) => {
      // localDataUpdate(
      //   data,
      //   cartItem,
      //   cartItems,
      //   cartItemsVar,
      //   localStorageKeys.cartItems,
      //   setDiscounted,
      //   setDiscountedPrice,
      //   locale,
      // )
    },
    onError: (error) => {
      console.log('Error in CartItem.tsx')
    },
  })

  //TODO: make better UI for loading state
  if (loading) return <Skeleton height="10rem" component="div" width="100%" />

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================
  const productProfileIndex: number = getProfileIndexFromLanguage(
    data.findUniqueProductContainer.single_product?.product_profiles,
    locale,
  )

  if (
    data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
  ) {
    const id = data.findUniqueProductContainer.id
    const permalink =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .meta_information.permalink
    const name =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .name
    const price = data.findUniqueProductContainer.single_product.price
    const dispatchInformation = data.findUniqueProductContainer.dispatch_information

    const quantity = data.findUniqueProductContainer.single_product.quantity
    const followProductStock =
      data.findUniqueProductContainer.single_product.follow_product_stock

    // const image = data.findUniqueProductContainer.single_product.images[0].src
    const images = data.findUniqueProductContainer.single_product.images
      .slice()
      .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
    const image = images
      ? images[0]?.src_thumbnail ?? images[0]?.src ?? imageFallback
      : imageFallback

    return (
      <>
        <Grid item xs={12} className={classes.gridItem}>
          <CardMedia image={image} className={classes.media} />
          <div className={classes.productInfoContainer}>
            <Link prefetch={false} href={`${productSlug}/${permalink}`} passHref>
              <span className="cursorPointer">
                <Typography variant="subtitle1">{name}</Typography>
              </span>
            </Link>
            {/* //TODO: calculate correct dispatch time and display it if needed */}
            {/* <Typography variant="body1">
              Estimated dispatch within 8 - 10 weeks
            </Typography> */}
            <div className={classes.pricesContainer}>
              <Typography
                variant="body1"
                className={clsx(
                  discounted ? classes.discountedPrice : classes.price,
                )}
              >
                <ProductPrice price={price} />
              </Typography>
              <Typography variant="body2">
                {discounted && <ProductPrice price={discountedPrice} />}
              </Typography>
            </div>
            <QuantitySelector
              cartItem={cartItem}
              client={client}
              productQuantity={quantity}
              followProductStock={followProductStock}
            />
            <div className={classes.removeButtonContainer}>
              <span className="cursorPointer">
                <Typography
                  variant="subtitle2"
                  onClick={() => {
                    removeFromCart(id)
                  }}
                  style={{ textDecoration: 'underline' }}
                >
                  {removeCartItemString}
                </Typography>
              </span>
              <Typography variant="subtitle1">
                {!discounted ? (
                  <ProductPrice price={price * cartItem.quantity} />
                ) : (
                  <ProductPrice price={discountedPrice * cartItem.quantity} />
                )}
              </Typography>
            </div>
          </div>
        </Grid>
        <Divider />
      </>
    )
  } else {
    return null
  }
}

const ResponsiveCartPageItemAdvancedProduct = ({ cartItem }) => {
  if (
    cartItem.advanced_product_data &&
    cartItem.advanced_product_data != null
  ) {
    if (cartItem.advanced_product_data.advanced_product_type === 'SOFA') {
      return <ResponsiveCartPageItemAdvancedProductSofa cartItem={cartItem} />
    } else if (
      cartItem.advanced_product_data.advanced_product_type ===
      'CHAIR_ARMCHAIR' ||
      cartItem.advanced_product_data.advanced_product_type === 'BED' ||
      cartItem.advanced_product_data.advanced_product_type ===
      'OTHER_WITH_FABRICS' ||
      cartItem.advanced_product_data.advanced_product_type === 'TABLE' ||
      cartItem.advanced_product_data.advanced_product_type === 'LIGHTS' ||
      cartItem.advanced_product_data.advanced_product_type === 'OTHER'
    ) {
      // return <p>Advanced chair component..</p>
      return (
        <ResponsiveCartPageItemAdvancedProductAdditionalComponents
          cartItem={cartItem}
        />
      )
    } else {
      return <p>Unhandled advanced product type</p>
    }
  } else {
    return <p>Advanced product data is missing</p>
  }
}

const ResponsiveCartPageItemAdvancedProductSofa = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItemString = t('remove-cartPage-item')
  const mobileUnitPriceString = t('mobile-unit-price')
  const classes = useStyles() as any
  const { locale } = useRouter()
  const client = useApolloClient()
  const cartItems = useReactiveVar(cartItemsVar)
  const specificationString = t('specification-text')

  // === UNHANDLED when swiching between lanugages (best to reset cart)
  // We allow to increment
  const followProductStock = true
  // Maximum number to increment
  const productQuantity = 1000

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const dispatchInformation = cartItem.dispatch_information

  //--- ADVANCED SOFA DATA -----
  const selectedFabric = cartItem.advanced_product_data.selected_fabric
  // We neeed to JSON Parse all object in array, because incoming data comes from local storage Stringified...
  const selectedSofaCombinations = parseSofaCombination(
    JSON.parse(cartItem.advanced_product_data.selected_sofa_combinations),
  )

  return (
    <>
      <Grid item xs={12} className={classes.gridItem}>
        <CardMedia image={image} className={classes.media} />
        <div className={classes.productInfoContainer}>
          <Link prefetch={false} href={`${productSlug}/${permalink}`} passHref>
            <span className="cursorPointer">
              <Typography variant="subtitle1">{name}</Typography>
            </span>
          </Link>
          {/* //TODO: calculate correct dispatch time and display it if needed */}
          {/* <Typography variant="body1">
              Estimated dispatch within 8 - 10 weeks
            </Typography> */}
          <div className={classes.pricesContainer}>
            <Typography
              variant="body1"
              className={clsx(
                discounted ? classes.discountedPrice : classes.price,
              )}
            >
              <ProductPrice price={price} />
            </Typography>
            <Typography variant="body2">
              {discounted && <ProductPrice price={discountedPrice} />}
            </Typography>
          </div>
          <QuantitySelector cartItem={cartItem} client={client} />
          <div className={classes.removeButtonContainer}>
            <span className="cursorPointer">
              <Typography
                variant="subtitle2"
                onClick={() => {
                  removeFromCart(id)
                }}
                style={{ textDecoration: 'underline' }}
              >
                {removeCartItemString}
              </Typography>
            </span>
            <Typography variant="subtitle1">
              {!discounted ? (
                <ProductPrice price={price * cartItem.quantity} />
              ) : (
                <ProductPrice price={discountedPrice * cartItem.quantity} />
              )}
            </Typography>
          </div>
        </div>
      </Grid>

      <Accordion sx={{ border: 'none', maxWidth: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{specificationString}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {selectedSofaCombinations.map((combination, i) => (
              <SofaCombinationCartPagePreview
                key={i}
                selectedFabric={selectedFabric}
                combination={combination}
                productName={name}
              />
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Divider />
    </>
  )
}

const ResponsiveCartPageItemAdvancedProductAdditionalComponents = ({
  cartItem,
}) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItemString = t('remove-cartPage-item')
  const mobileUnitPriceString = t('mobile-unit-price')
  const classes = useStyles() as any
  const { locale } = useRouter()
  const client = useApolloClient()
  const cartItems = useReactiveVar(cartItemsVar)
  const specificationString = t('specification-text')

  // === UNHANDLED when swiching between lanugages (best to reset cart)
  // We allow to increment
  const followProductStock = true
  // Maximum number to increment
  const productQuantity = 1000

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const dispatchInformation = cartItem.dispatch_information

  //--- ADVANCED SOFA DATA -----
  const selectedFabric = cartItem.advanced_product_data.selected_fabric

  const selectedAdditionalComponents =
    cartItem.advanced_product_data.selected_additional_components

  const discount = cartItem.advanced_product_data.discount

  // console.log('discount :>> ', discount)
  // console.log('cartItem :>> ', cartItem)

  const additionalComponentGroups =
    cartItem.advanced_product_data.additional_component_groups

  const dimensions = cartItem.advanced_product_data.dimensions

  const advancedProductType =
    cartItem?.advanced_product_data?.advanced_product_type ?? 'OTHER'

  const selectedAdditionalComponentsWithGroupNames = selectedAdditionalComponents.map(component => ({
    ...component,
    groupName: getComponentGroupName(additionalComponentGroups, component.additionalComponentGroupId, locale)
  }))

  return (
    <>
      <Grid item xs={12} className={classes.gridItem}>
        <CardMedia image={image} className={classes.media} />
        <div className={classes.productInfoContainer}>
          <Link prefetch={false} href={`${productSlug}/${permalink}`} passHref>
            <span className="cursorPointer">
              <Typography variant="subtitle1">{name}</Typography>
            </span>
          </Link>
          {/* //TODO: calculate correct dispatch time and display it if needed */}
          {/* <Typography variant="body1">
              Estimated dispatch within 8 - 10 weeks
            </Typography> */}
          <div className={classes.pricesContainer}>
            <Typography
              variant="body1"
              className={clsx(
                discounted ? classes.discountedPrice : classes.price,
              )}
            >
              <ProductPrice price={price} />
            </Typography>
            <Typography variant="body2">
              {discounted && <ProductPrice price={discountedPrice} />}
            </Typography>
          </div>
          <QuantitySelector cartItem={cartItem} client={client} />
          <div className={classes.removeButtonContainer}>
            <span className="cursorPointer">
              <Typography
                variant="subtitle2"
                onClick={() => {
                  removeFromCart(id)
                }}
                style={{ textDecoration: 'underline' }}
              >
                {removeCartItemString}
              </Typography>
            </span>
            <Typography variant="subtitle1">
              {!discounted ? (
                <ProductPrice price={price * cartItem.quantity} />
              ) : (
                <ProductPrice price={discountedPrice * cartItem.quantity} />
              )}
            </Typography>
          </div>
        </div>
      </Grid>

      <Accordion sx={{ border: 'none', maxWidth: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{specificationString}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <AdvancedProductCartPagePreview
              selectedFabric={selectedFabric}
              selectedAddtionalComponents={selectedAdditionalComponentsWithGroupNames}
              discount={discount}
              dimensions={dimensions}
              advancedProductType={advancedProductType}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

      <Divider />
    </>
  )
}
