import { useQuery, useReactiveVar } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Skeleton } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from '../../../operations/clientMutations/cartClient'
import { GET_PRODUCT_IN_CART } from '../../../operations/queries/headerQueries'
import { CartItem } from '../../../store/models'
import { cartItemsVar } from '../../../store/reactiveVars'
import { getProfileIndexFromLanguage } from '../../../utilityFunctions/getProfile'
import {
  localDataUpdate,
  localStorageKeys,
} from '../../../utilityFunctions/localDataUpdate'
import { priceFormatter } from '../../../utilityFunctions/priceUtils'
import { parseSofaCombination } from '../../../utilityFunctions/parseSofaCombination'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import dynamic from 'next/dynamic'
import SofaConfigurationBlock from '../ProductPageElements/AdvancedProductElements/Components/SofaConfigurationBlock'
import { imageFallback } from '../../../constants/Images'
import ConfigurationBlock from '../ProductPageElements/AdvancedProductElements/Components/ConfigurationBlock'
import AdvancedProductCartPagePreview from './AdvancedProductCartPagePreview'
import PreviewSelectedAdditionalComponent from '../ProductPageElements/AdvancedProductElements/Components/PreviewSelectedAdditionalComponent'
import { ProductPrice } from '../../UtilityComponents/price'
import { getComponentGroupName } from '../../../utilityFunctions/getComponentGroupName'

// import SofaCombinationCartPreview from '../HeaderElements/SofaCombinationCartPreview'
const SofaCombinationCartPreview = dynamic(
  () => import('../HeaderElements/SofaCombinationCartPreview'),
  {
    ssr: false,
  },
)

// import SofaCombinationCartPagePreview from './SofaCombinationCartPagePreview'
const SofaCombinationCartPagePreview = dynamic(
  () => import('./SofaCombinationCartPagePreview'),
  {
    ssr: false,
  },
)

const useStyles = makeStyles(
  (theme) => ({
    tableRow: {
      backgroundColor: '#fff',
      borderBottom: '1px solid #d3d3d3',
    },
    tableCell: {
      borderBottom: 0,
    },
    productBody: {
      display: 'grid',
      gridTemplateColumns: '1fr 5fr',
      gap: '2rem',
      height: '8rem',
      [theme.breakpoints.down('lg')]: {
        gap: theme.spacing(1),
      },
      'MuiTableCell-root': {
        padding: 0,
      },
    },
    media: {
      height: '100%',
      backgroundSize: 'contain',
    },
    //TODO: change quantity selector to the standard component
    quantitySelector: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #d3d3d3',
      maxWidth: '7rem',
      padding: theme.spacing(0.5, 1),
      margin: 'auto',
    },
    mediaContainer: {
      height: '50%',
      alignSelf: 'center',
    },
    nextImage: {
      height: '100%',
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    icon: {
      cursor: 'pointer',
      fontSize: '1rem',
    },
    discountedPrice: {
      textDecoration: 'line-through',
    },
    removeButton: {
      textDecoration: 'underline',
    },
    imageSkeleton: {},
  }),
  { name: 'MuiCartPageItemComponent' },
)

export default function CartPageItem({ cartItem }) {
  if (cartItem.product_type === 'SIMPLE_PRODUCT') {
    return <CartPageItemSingleProduct cartItem={cartItem} />
  } else if (cartItem.product_type === 'ADVANCED_PRODUCT') {
    return <CartPageItemAdvancedProduct cartItem={cartItem} />
  } else {
    // unhandlend entry
    return <p>Unhandled product type</p>
  }
}

const CartPageItemSingleProduct = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItemString = t('remove-cartPage-item')
  const classes = useStyles() as any
  //I can use useRouter() to get the locale
  // Since cartItems won't be statically built
  const { locale } = useRouter()
  const cartItems = useReactiveVar(cartItemsVar)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discounted, setDiscounted] = useState(false)

  //* =================================================================================
  //* ==================  query to get latest cartItems info ======================
  //* =================================================================================
  const { data, loading, error, called } = useQuery(GET_PRODUCT_IN_CART, {
    variables: {
      itemID: cartItem.id,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      //On completed we will have the data and we want to update
      //all the static data in the reactive var and localstorage
      // ! Deprecated ----> We will neet to replace this...
      localDataUpdate(
        data,
        cartItem,
        cartItems,
        cartItemsVar,
        localStorageKeys.cartItems,
        setDiscounted,
        setDiscountedPrice,
        locale,
      )
      //-------------------------
    },
    onError: (error) => {
      console.log('There was a problem fetching this cart page item...')
    },
  })

  const followProductStock = data
    ? data.productContainer?.single_product?.follow_product_stock
    : false
  const productQuantity = data
    ? data.productContainer?.single_product?.quantity
    : 0

  const id = data ? data.productContainer?.id : null

  // We need to reset cartItem quantity if its too big
  useEffect(() => {
    if (id) {
      // console.log("cartItem.id ->", cartItem.id);
      // console.log("followProductStock :>> ", followProductStock);
      // console.log("productQuantity :>> ", productQuantity);

      // const cartItems: CartItem[] = JSON.parse(
      //   localStorage.getItem('cartItems'),
      // )

      const cartItems: CartItem[] = cartItemsVar()

      if (cartItems) {
        cartItems.forEach((cartItem, i) => {
          if (cartItem.id === id) {
            cartItems.splice(i, 1, {
              ...cartItem,
              quantity: followProductStock
                ? cartItem.quantity >= productQuantity
                  ? productQuantity
                  : cartItem.quantity
                : cartItem.quantity,
            })
            // --- if quantity is 0 then remove from cart...
            if (followProductStock && productQuantity <= 0) {
              console.log('REMOVE CART ITEM!')
              // console.log("cartItem.id ->", cartItem.id);
              // console.log("followProductStock :>> ", followProductStock);
              // console.log("productQuantity :>> ", productQuantity);
              // cartItems.splice(i, 1);
            }

            return
          }
        })
      } else {
        return
      }

      localStorage.setItem('cartItems', JSON.stringify([...cartItems]))
      cartItemsVar([...cartItems])
    }
  }, [data])

  //TODO: make better UI for loading state
  if (loading) return <Skeleton height="10rem" component="tr" width="100%" />

  //* ======================================================
  //* Once the data has come (we are after the "loading" check)
  //* we want to run the check and get this item's profile
  //* ======================================================
  const productProfileIndex: number = getProfileIndexFromLanguage(
    data.findUniqueProductContainer.single_product?.product_profiles,
    locale,
  )

  //* ============================================================
  //* Here we know if the profile exists or not, if it does
  //* this component can be displayed in the current locale
  //* else this item will be removed from client data by the
  //* above useEffect
  //* =============================================================
  if (productProfileIndex !== null && productProfileIndex !== undefined) {
    const id = data.findUniqueProductContainer.id
    const permalink =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .meta_information.permalink
    const name =
      data.findUniqueProductContainer.single_product?.product_profiles[productProfileIndex]
        .name
    const price = data.findUniqueProductContainer.single_product.price
    const dispatchInformation = data.findUniqueProductContainer.dispatch_information

    const minimum_order_quantity =
      data.findUniqueProductContainer.single_product?.minimum_order_quantity ?? null

    // const image = data.findUniqueProductContainer.single_product.images[0].src

    const images = data.findUniqueProductContainer.single_product.images
      .slice()
      .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))
    const image = images
      ? images[0]?.src_thumbnail ?? images[0]?.src ?? imageFallback
      : imageFallback

    const discounted = cartItem.discounted
    const discountedPrice = cartItem.discountedPrice

    return (
      <TableRow className={classes.tableRow}>
        <TableCell
          component="td"
          scope="row"
          className={classes.productBody}
          classes={{
            root: classes.tableCell,
          }}
        >
          <Link href={`/${productSlug}/${permalink}`}>
            <div className={classes.mediaContainer}>
              <span className="cursorPointer">
                <CardMedia image={image} className={classes.media} />
              </span>
            </div>
          </Link>
          <div className={classes.nameContainer}>
            <div>
              <Link href={`/${productSlug}/${permalink}`}>
                <span className="cursorPointer">
                  <Typography variant="body2">{name}</Typography>
                </span>
              </Link>
              {
                //TODO: calculate the correct dispatch time here
                // dispatchInformation && (
                //   <Typography variant="caption">
                //     {`Estimated dispatch within ${dispatchInformation.manufacturing_time} weeks`}
                //   </Typography>
                // )
              }
            </div>
            <span className="cursorPointer">
              <Typography
                variant="body1"
                onClick={() => {
                  removeFromCart(id)
                }}
                className={classes.removeButton}
              >
                {removeCartItemString}
              </Typography>
            </span>
          </div>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <Typography variant="body2">
            {discounted && <ProductPrice price={discountedPrice} />}
          </Typography>
          <Typography
            variant={discounted ? 'body1' : 'body2'}
            className={clsx(discounted && classes.discountedPrice)}
          >
            <ProductPrice price={price} />
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <div className={classes.quantitySelector}>
            <RemoveIcon
              className={classes.icon}
              color={cartItem.quantity === 1 ? `disabled` : `inherit`}
              onClick={() =>
                decrementQuantity({ cartItemID: id, minimum_order_quantity })
              }
            />
            <Typography variant="subtitle2">{cartItem.quantity}</Typography>
            <AddIcon
              className={classes.icon}
              color={
                followProductStock
                  ? cartItem.quantity >= productQuantity
                    ? `disabled`
                    : `inherit`
                  : `inherit`
              }
              onClick={() =>
                incrementQuantity({
                  cartItemID: id,
                  followProductStock,
                  productQuantity,
                  minimum_order_quantity,
                })
              }
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <Typography variant="body2">
            {!discounted ? (
              <ProductPrice price={price * cartItem.quantity} />
            ) : (
              <ProductPrice price={discountedPrice * cartItem.quantity} />
            )}
          </Typography>
        </TableCell>
      </TableRow>
    )
  } else {
    return null
  }
}

const CartPageItemAdvancedProduct = ({ cartItem }) => {
  if (
    cartItem.advanced_product_data &&
    cartItem.advanced_product_data != null
  ) {
    if (cartItem.advanced_product_data.advanced_product_type === 'SOFA') {
      return <CartPageItemAdvancedProductSofa cartItem={cartItem} />
    } else if (
      cartItem.advanced_product_data.advanced_product_type ===
      'CHAIR_ARMCHAIR' ||
      cartItem.advanced_product_data.advanced_product_type === 'BED' ||
      cartItem.advanced_product_data.advanced_product_type ===
      'OTHER_WITH_FABRICS' ||
      cartItem.advanced_product_data.advanced_product_type === 'TABLE' ||
      cartItem.advanced_product_data.advanced_product_type === 'LIGHTS' ||
      cartItem.advanced_product_data.advanced_product_type === 'OTHER'
    ) {
      // return <p>Advanced chair component..</p>
      return (
        <CartPageItemAdvancedProductAdditionalComponents cartItem={cartItem} />
      )
    } else {
      return <p>Unhandled advanced product type</p>
    }
  } else {
    return <p>Advanced product data is missing</p>
  }
}

const CartPageItemAdvancedProductSofa = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItemString = t('remove-cartPage-item')
  const specificationString = t('specification-text')
  const additionalConfigurationText = t('choose-additional-components-text')

  const classes = useStyles() as any
  //I can use useRouter() to get the locale
  // Since cartItems won't be statically built
  const { locale } = useRouter()
  const cartItems = useReactiveVar(cartItemsVar)

  // === UNHANDLED when swiching between lanugages (best to reset cart)
  // We allow to increment
  const followProductStock = true
  // Maximum number to increment
  const productQuantity = 1000

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const dispatchInformation = cartItem.dispatch_information

  let discountPercentage = null
  if (discounted) {
    discountPercentage = Math.round((1 - discountedPrice / price) * 100)
  }
  const discount = { discount: discountPercentage }

  //--- ADVANCED SOFA DATA -----
  const selectedFabric = cartItem.advanced_product_data.selected_fabric
  // We neeed to JSON Parse all object in array, because incoming data comes from local storage Stringified...
  const selectedSofaCombinations = parseSofaCombination(
    JSON.parse(cartItem.advanced_product_data.selected_sofa_combinations),
  )

  const selectedAdditionalComponents =
    cartItem?.advanced_product_data?.selected_additional_components ?? []

  let selectedAdditionalComponentGroups =
    cartItem?.advanced_product_data?.additional_component_groups ?? []

  // console.log('selectedSofaCombinations :>> ', selectedSofaCombinations)
  // console.log('selectedFabric :>> ', selectedFabric)
  const selectedAdditionalComponentsWithGroupNames = selectedAdditionalComponents.map(component => ({
    ...component,
    groupName: getComponentGroupName(selectedAdditionalComponentGroups, component.additionalComponentGroupId, locale)
  }))
  console.log('selectedAdditionalComponentsWithGroupNames :>> ', selectedAdditionalComponentsWithGroupNames)

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell
          component="td"
          scope="row"
          className={classes.productBody}
          classes={{
            root: classes.tableCell,
          }}
        >
          <Link href={`/${productSlug}/${permalink}`}>
            <div className={classes.mediaContainer}>
              <span className="cursorPointer">
                <CardMedia image={image} className={classes.media} />
              </span>
            </div>
          </Link>
          <div className={classes.nameContainer}>
            <div>
              <Link href={`/${productSlug}/${permalink}`}>
                <span className="cursorPointer">
                  <Typography variant="body2">{name}</Typography>
                </span>
              </Link>
              {
                //TODO: calculate the correct dispatch time here
                // dispatchInformation && (
                //   <Typography variant="caption">
                //     {`Estimated dispatch within ${dispatchInformation.manufacturing_time} weeks`}
                //   </Typography>
                // )
              }
            </div>
            <span className="cursorPointer" style={{ width: 'fit-content' }}>
              <Typography
                variant="body1"
                onClick={() => {
                  removeFromCart(id)
                }}
                className={classes.removeButton}
              >
                {removeCartItemString}
              </Typography>
            </span>
          </div>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <Typography variant="body2">
            {discounted && <ProductPrice price={discountedPrice} />}
          </Typography>
          <Typography
            variant={discounted ? 'body1' : 'body2'}
            className={clsx(discounted && classes.discountedPrice)}
          >
            <ProductPrice price={price} />
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <div className={classes.quantitySelector}>
            <RemoveIcon
              className={classes.icon}
              color={cartItem.quantity === 1 ? `disabled` : `inherit`}
              onClick={() => decrementQuantity({ cartItemID: id })}
            />
            <Typography variant="subtitle2">{cartItem.quantity}</Typography>
            <AddIcon
              className={classes.icon}
              color={
                followProductStock
                  ? cartItem.quantity >= productQuantity
                    ? `disabled`
                    : `inherit`
                  : `inherit`
              }
              onClick={() =>
                incrementQuantity({
                  cartItemID: id,
                })
              }
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <Typography variant="body2">
            {!discounted ? (
              <ProductPrice price={price * cartItem.quantity} />
            ) : (
              <ProductPrice price={discountedPrice * cartItem.quantity} />
            )}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={4} sx={{ padding: 0 }}>
          <Accordion sx={{ border: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{specificationString}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.advancedPreviewBox}>
                {selectedSofaCombinations.map((combination, i) => (
                  <SofaCombinationCartPagePreview
                    key={i}
                    selectedFabric={selectedFabric}
                    combination={combination}
                    productName={name}
                  />
                ))}
              </Box>
              <Box sx={{ padding: '20px', borderTop: '1px solid #eeeeee' }}>
                <Typography variant="h6" component="h6">
                  {additionalConfigurationText}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'flex-start',
                  }}
                >
                  {selectedAdditionalComponentsWithGroupNames.map((component, i) => (
                    <Box key={component.id} sx={{ marginRight: '10px' }}>
                      <PreviewSelectedAdditionalComponent
                        key={component.id}
                        selectedAdditionalComponent={component}
                        discount={discount}
                        selectedFabric={selectedFabric}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    </>
  )
}

const CartPageItemAdvancedProductAdditionalComponents = ({ cartItem }) => {
  const { t, i18n } = useTranslation(['common'])
  const productSlug = t('product-slug')
  const removeCartItemString = t('remove-cartPage-item')
  const specificationString = t('specification-text')

  const classes = useStyles() as any
  //I can use useRouter() to get the locale
  // Since cartItems won't be statically built
  const { locale } = useRouter()
  const cartItems = useReactiveVar(cartItemsVar)

  // === UNHANDLED when swiching between lanugages (best to reset cart)
  // We allow to increment
  const followProductStock = true
  // Maximum number to increment
  const productQuantity = 1000

  //-----
  const discounted = cartItem.discounted
  const discountedPrice = cartItem.discountedPrice
  const id = cartItem.id
  const permalink = cartItem.permalink
  const image = cartItem.image
  const name = cartItem.name
  const price = cartItem.price
  const dispatchInformation = cartItem.dispatch_information

  //--- ADVANCED SOFA DATA -----
  const selectedFabric = cartItem.advanced_product_data.selected_fabric

  const selectedAdditionalComponents =
    cartItem.advanced_product_data.selected_additional_components

  const discount = cartItem.advanced_product_data.discount

  // console.log('discount :>> ', discount)


  const additionalComponentGroups = cartItem.advanced_product_data.additional_component_groups

  const dimensions = cartItem.advanced_product_data.dimensions

  const advancedProductType =
    cartItem?.advanced_product_data?.advanced_product_type ?? 'OTHER'

  const selectedAdditionalComponentsWithGroupNames = selectedAdditionalComponents.map(component => ({
    ...component,
    groupName: getComponentGroupName(additionalComponentGroups, component.additionalComponentGroupId, locale)
  }))

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell
          component="td"
          scope="row"
          className={classes.productBody}
          classes={{
            root: classes.tableCell,
          }}
        >
          <Link href={`/${productSlug}/${permalink}`}>
            <div className={classes.mediaContainer}>
              <span className="cursorPointer">
                <CardMedia image={image} className={classes.media} />
              </span>
            </div>
          </Link>
          <div className={classes.nameContainer}>
            <div>
              <Link href={`/${productSlug}/${permalink}`}>
                <span className="cursorPointer">
                  <Typography variant="body2">{name}</Typography>
                </span>
              </Link>
              {
                //TODO: calculate the correct dispatch time here
                // dispatchInformation && (
                //   <Typography variant="caption">
                //     {`Estimated dispatch within ${dispatchInformation.manufacturing_time} weeks`}
                //   </Typography>
                // )
              }
            </div>
            <span className="cursorPointer" style={{ width: 'fit-content' }}>
              <Typography
                variant="body1"
                onClick={() => {
                  removeFromCart(id)
                }}
                className={classes.removeButton}
              >
                {removeCartItemString}
              </Typography>
            </span>
          </div>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <Typography variant="body2">
            {discounted && <ProductPrice price={discountedPrice} />}
          </Typography>
          <Typography
            variant={discounted ? 'body1' : 'body2'}
            className={clsx(discounted && classes.discountedPrice)}
          >
            <ProductPrice price={price} />
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <div className={classes.quantitySelector}>
            <RemoveIcon
              className={classes.icon}
              color={cartItem.quantity === 1 ? `disabled` : `inherit`}
              onClick={() => decrementQuantity({ cartItemID: id })}
            />
            <Typography variant="subtitle2">{cartItem.quantity}</Typography>
            <AddIcon
              className={classes.icon}
              color={
                followProductStock
                  ? cartItem.quantity >= productQuantity
                    ? `disabled`
                    : `inherit`
                  : `inherit`
              }
              onClick={() =>
                incrementQuantity({
                  cartItemID: id,
                })
              }
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          component="td"
          scope="row"
          classes={{
            root: classes.tableCell,
          }}
        >
          <Typography variant="body2">
            {!discounted ? (
              <ProductPrice price={price * cartItem.quantity} />
            ) : (
              <ProductPrice price={discountedPrice * cartItem.quantity} />
            )}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={4} sx={{ padding: 0 }}>
          <Accordion sx={{ border: 'none' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{specificationString}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className={classes.advancedPreviewBox}>
                <AdvancedProductCartPagePreview
                  selectedFabric={selectedFabric}
                  selectedAddtionalComponents={selectedAdditionalComponentsWithGroupNames}
                  discount={discount}
                  dimensions={dimensions}
                  advancedProductType={advancedProductType}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    </>
  )
}
