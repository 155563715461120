import { Divider, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import { AccessTime } from '@mui/icons-material'
import moment from 'moment'
import 'moment/locale/et'
// Languages...
import 'moment/locale/lt'
import 'moment/locale/lv'
import 'moment/locale/ru'
import { useTranslation } from 'next-i18next'
import React from 'react'

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      row: {
        // cursor: "pointer",
        display: 'flex',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        alignItems: 'center',
      },

      rowContentContainer: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        flexWrap: 'wrap',
      },

      chevronRight: {
        cursor: 'pointer',
      },

      clockIcon: {
        fontSize: '30px',
        color: theme.palette.text.secondary,
        marginRight: '15px',
      },
    }),
  { name: 'MuiDeliveryInformationBox' },
)

// TODO Maybe think about usinmanufacturing_time and shipping_time inside variantsDrawerStateVar (form that opens on the right...)
const DeliveryInformationBox = ({
  total_delivery_time,
  manufacturing_time,
  shipping_time,
  locale,
}) => {
  const classes = useStyles() as any

  const { t } = useTranslation(['common'])
  const afterPayment = t('after-payment')
  const delivery_string = t('delivery_string')

  const string1 = t('date-string-1')
  const string2 = t('date-string-2')

  // const deliveryTimeString = convertDaysToWeeks(total_delivery_time);
  const deliveryTimeString = convertDaysToWeeks(
    total_delivery_time,
    locale,
    string1,
    string2,
  )

  return (
    <>
      <div
        className={classes.row}
        // onClick={() => {
        //   variantsDrawerStateVar({
        //     open: true,
        //     component: "DELIVERY_INFORMATION_FORM",
        //   });
        // }}
      >
        <AccessTime className={classes.clockIcon} />

        <div className={classes.rowContentContainer}>
          <Typography
            component="p"
            variant="body1"
            style={{ marginRight: '5px' }}
          >
            {delivery_string}
          </Typography>
          <Typography
            component="p"
            variant="body2"
            style={{ marginRight: '5px' }}
          >
            {deliveryTimeString}
          </Typography>
          <Typography component="p" variant="body1">
            {afterPayment}.
          </Typography>
        </div>

        {/* <ChevronRightIcon className={classes.chevronRight} /> */}
      </div>
      <Divider light />
    </>
  )
}

export default DeliveryInformationBox

// Take in days and return a string...
// if less than 14, then number in days
// If more then 2-3 weeks etc..
export const convertDaysToWeeks = (timeInDays, locale, string1, string2) => {
  // console.log("pristatymas ->", timeInDays);
  // console.log("locale :>> ", locale);

  var d = new Date()
  d.setDate(d.getDate() - timeInDays)
  let deliveryString = ''

  if (timeInDays < 16) {
    var start = moment(d)
    var end = moment(new Date())
    end.locale(locale)
    moment.relativeTimeThreshold('d', 16)
    deliveryString = end.from(start)
  } else {
    const now = moment()
    // const days = now.diff(d, "days");
    const weeks = now.diff(d, 'weeks') + 1
    const weekBefore = weeks - 1
    // console.log('weeks :>> ', weeks)
    // console.log('d :>> ', d)

    deliveryString = string1 + ' ' + weekBefore + '-' + weeks + ' ' + string2
  }
  return deliveryString
}
