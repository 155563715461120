import { useReactiveVar } from '@apollo/client'
import { Button, Drawer, Theme, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { cartItemsVar, cartStateVar } from '../../../store/reactiveVars'
import CartItemComponent from './CartItemComponent'
import { TrackingModuleButton } from '../Utils/TrackingModuleComponent'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      cart: {
        width: 450,
        [theme.breakpoints.only('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.between('sm', 'lg')]: {
          width: '50%',
        },
      },
      topCartContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        padding: '1rem',
        overflow: 'hidden',
      },
      cartContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'hidden',
      },
      cartContentFooter: {
        padding: theme.spacing(0, 2, 2, 2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      closeIcon: {
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          opacity: '0.6',
        },
      },
    }),
  { name: 'MuiCartDrawerComponent' },
)

export default function CartDrawer() {
  const { t } = useTranslation(['common'])
  const cartSlug = t('cart-slug')
  const cartDrawerHeader = t('cart-drawer-header')
  const goToCartCtaButton = t('go-to-cart-cta-button')
  const classes = useStyles() as any
  const cartState = useReactiveVar(cartStateVar)
  const cartItems = useReactiveVar(cartItemsVar)
  const { locale } = useRouter()

  //TODO: create empty state where we don't show the button
  //TODO: add a button in case they want to go directly to checkout without passing through cart page

  // console.log('cartItems :>> ', cartItems)
  let cartIncludesAdvancedProducts = false
  for (const cartItem of cartItems) {
    if (cartItem.product_type === 'ADVANCED_PRODUCT') {
      cartIncludesAdvancedProducts = true
      break
    }
  }

  return (
    <>
      <Drawer
        classes={{
          paper: classes.cart,
        }}
        anchor="right"
        open={cartState}
        transitionDuration={{ enter: 600, exit: 200 }}
        onClose={() => cartStateVar(false)}
      >
        <div className={classes.topCartContent}>
          <Typography variant="h6">{cartDrawerHeader}</Typography>
          <CloseIcon
            className={classes.closeIcon}
            onClick={() => cartStateVar(false)}
          />
        </div>

        <section className={classes.cartContent}>
          <div style={{ overflowY: 'scroll' }}>
            {cartItems.map((cartItem, i) => {
              return <CartItemComponent cartItem={cartItem} key={i} />
            })}
          </div>
          <div>
            {/* //* ============= TRACKING MODULE SECTION */}
            {cartIncludesAdvancedProducts && (
              <div className={classes.cartContentFooter}>
                <TrackingModuleButton
                  type={'order-a-fabric-or-material-sample-button'}
                  variant="outlined"
                />
              </div>
            )}
            <div className={classes.cartContentFooter}>
              <Link prefetch={false} href={`/${cartSlug}`}>
                <Button variant="contained" onClick={() => cartStateVar(false)}>
                  {goToCartCtaButton}
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </Drawer>
    </>
  )
}
