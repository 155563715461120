import { v4 as uuidv4 } from 'uuid'

//---- SETS The session
export function getUserIdFromCookieClient() {
  let userSessionId = getCookie('userSessionId')

  if (!userSessionId) {
    userSessionId = uuidv4()
    setCookie('userSessionId', userSessionId)
  }

  return userSessionId
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}

export function setCookie(name, value) {
  const oneYearInSeconds = 365 * 24 * 60 * 60
  document.cookie = `${name}=${value}; Path=/; Max-Age=${oneYearInSeconds}`
}
//---------

// Function to save customer information in a cookie:
export function saveCustomerInfoToCookie(customer) {
  const customerInfo = JSON.stringify(customer)
  setCookie('customerInfo', customerInfo)
}

// Function to retrieve customer information from a cookie:
export function getCustomerInfoFromCookie() {
  const customerInfo = getCookie('customerInfo')
  if (customerInfo) {
    return JSON.parse(customerInfo)
  }
  return null
}

//Function to clear customer information from a cookie:
export function clearCustomerInfoCookie() {
  setCookie('customerInfo', '') // Set an expired date to clear the cookie
}

// --- server side version below

// import { v4 as uuidv4 } from 'uuid'

// export function getUserIdFromCookie(req, res) {
//   // Check if the cookie already has the user ID
//   let userId = req.cookies.userId

//   // If not, create a new unique ID and set it in the cookie
//   if (!userId) {
//     userId = uuidv4()
//     const oneYear = 365 * 24 * 60 * 60 * 1000 // 1 year in milliseconds
//     res.setHeader('Set-Cookie', `userId=${userId}; Path=/; Max-Age=${oneYear}`)
//   }

//   return userId
// }

// ==== EXAMPLE USAGE ====
// USED in ADD TO CART

// import { getUserIdFromCookie } from '../../utils/userId';

// export default function handler(req, res) {
//   const userId = getUserIdFromCookie(req, res);
//   res.json({ userId });
// }

// // OR in getServerSideProps

// export async function getServerSideProps(context) {
//   const userId = getUserIdFromCookie(context.req, context.res);
//   return {
//     props: { userId }
//   };
// }
