import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close' // Material-UI Close Icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle' // Material-UI Check Circle Icon
import { useTranslation } from 'react-i18next'
import {
  getCustomerInfoFromCookie,
  saveCustomerInfoToCookie,
  getCookie,
} from '../../../../lib/getUserIdFromCookie'
import { useMutation, gql } from '@apollo/client'
import { LinearProgress } from '@mui/material'

// Define your GraphQL mutation
const SAVE_CUSTOMER_TO_SESSION = gql`
  mutation SaveCustomerToSession(
    $cookie: String!
    $email: String!
    $phoneNumber: String
    $type: String
  ) {
    saveCustomerToSession(
      cookie: $cookie
      email: $email
      phoneNumber: $phoneNumber
      type: $type
    )
  }
`

interface TrackingUserModalProps {
  open: boolean
  onClose: () => void
  type: string
}

const TrackingUserModal: React.FC<TrackingUserModalProps> = ({
  open,
  onClose,
  type,
}) => {
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [success, setSuccess] = useState(false)
  const [emailError, setEmailError] = useState(false) // Added emailError state
  //---- Translations
  const { t } = useTranslation(['common'])
  const phoneNumberContact = t('phone-number-contact')
  const emailContact = t('email-contact')
  const nextStepButton =
    t('next-step-button').charAt(0).toUpperCase() +
    t('next-step-button').slice(1)

  const successString = t('success')
  const closeButtonString = t('close')

  // Define the mutation hook
  const [saveCustomerToSessionMutation, { loading }] = useMutation(
    SAVE_CUSTOMER_TO_SESSION,
  )

  useEffect(() => {
    // Retrieve customer information from cookie
    const savedCustomer = getCustomerInfoFromCookie()
    // console.log('savedCustomer :>> ', savedCustomer)
    if (savedCustomer) {
      setEmail(savedCustomer.email)
      setPhoneNumber(savedCustomer.phoneNumber)
    }
    //---
  }, [open])

  const handleClose = () => {
    setEmail('')
    setPhoneNumber('')
    setSuccess(false)
    onClose()
  }

  const handleSave = async () => {
    // Verify if the email is valid
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

    if (isValidEmail) {
      // Handle saving the user data (email and phone number)
      // You can perform validation and send the data to your backend here
      // For this example, we'll simulate a success state.
      console.log('Email:', email)
      console.log('Phone Number:', phoneNumber)
      //--- Save customer data to cookie
      const customer = { email, phoneNumber }
      saveCustomerInfoToCookie(customer)
      //-----

      // Call the GraphQL mutation with the required arguments
      await saveCustomerToSessionMutation({
        variables: {
          cookie: getCookie('userSessionId'), // Replace with your cookie name
          email,
          phoneNumber,
          type,
        },
      })
      //----------
      setSuccess(true)
      setEmailError(false) // Reset emailError state
    } else {
      // Display an error for an invalid email
      setEmailError(true)
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        <IconButton
          aria-label="Close"
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {success ? (
          <div>
            <CheckCircleIcon sx={{ fontSize: 100, color: 'green' }} />
            <p style={{ margin: 10 }}>{successString}</p>
            <Button variant="contained" color="primary" onClick={handleClose}>
              {closeButtonString}
            </Button>
          </div>
        ) : (
          <div>
            <TextField
              label={emailContact + '*'}
              fullWidth
              variant="outlined"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError} // Apply error styling if emailError is true
              helperText={emailError ? 'Invalid email format' : ''}
            />
            <TextField
              label={phoneNumberContact}
              fullWidth
              variant="outlined"
              margin="normal"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />

            {loading && <LinearProgress />}

            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}
            >
              {nextStepButton}
            </Button>
          </div>
        )}
      </Box>
    </Modal>
  )
}

export default TrackingUserModal
