import { CardMedia, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import React from 'react'
import SwiperCore, {
  Autoplay,
  EffectFade,
  Lazy,
  Navigation,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { imageFallback } from '../../../constants/Images'
import { useAppContext } from '../../../context/appContext'
import { checkIfProductIsNew } from '../../../utilityFunctions/checkIfProductIsNew'
import { checkDiscountValidity } from '../../../utilityFunctions/discountValidityCheck'
import { dispatchInformationDate } from '../../../utilityFunctions/dispatchInformationDate'
import DiscountBadge from '../../DesktopElements/Utils/DiscountBadge'
import FastDeliveryBadge from '../../DesktopElements/Utils/FastDeliveryBadge'
import NewProductBadge from '../../DesktopElements/Utils/NewProductBadge'

// swiper bundle styles
import 'swiper/css/bundle'

// swiper core styles
import 'swiper/css'

// modules styles
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const useStyles = makeStyles(
  (theme: Theme) => ({
    media: {
      height: '55vh',
      backgroundSize: 'contain',
    },
    slider: {
      '--swiper-pagination-color': 'black',
    },

    badgeBox: {
      position: 'absolute',
      top: 5,
      right: 0,
      // width: "15%",
      zIndex: 20,
    },
  }),
  { name: 'MuiMobileProductPageSliderComponent' },
)

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay, Lazy])

const MobileProductPageSlider = ({
  className,
  images,
  product,
  ...rest
}: SimpleSliderProps): JSX.Element => {
  const classes = useStyles() as any

  // Order images by display order
  // Convert to list
  const productImagesArranged = images
    .slice()
    .sort((a, b) => (a.display_order > b.display_order ? 1 : -1))

  // =============================================
  // ================ BADGES =====================
  //? =============================================
  // ============ DISCOUNTS ======================
  const { discounted, discountedPrice } = checkDiscountValidity(
    product.single_product?.product_container?.discount ??
      product.advanced_product?.product_container?.discount,
    product.single_product?.price ?? product.advanced_product?.price_from,
  )
  const discountPercentage = product.single_product?.product_container?.discount
    ? '-' + product.single_product.product_container.discount.discount + '%'
    : product.advanced_product?.product_container?.discount
    ? '-' + product.advanced_product.product_container.discount.discount + '%'
    : 0

  // ========== DISPATCH INFORMATION ===============
  // ==== We get shop settings data from APP Context
  const appContext = useAppContext()
  // === We need to get dispatch data from SHOP Settings
  const shopSettings = appContext ? appContext.shopSettings : null
  const defaultDispatchInformation = appContext
    ? appContext.shopSettings.default_dispatch_information
    : null
  // === We need to get dispatch data from MANUFACTURER
  const manufacturerDefaultDispatch = product.single_product?.product_container
    ?.manufacturer
    ? product.single_product.product_container.manufacturer
        .default_dispatch_information
    : product.advanced_product?.product_container?.manufacturer
    ? product.advanced_product.product_container.manufacturer
        .default_dispatch_information
    : null

  const dispatchTime = dispatchInformationDate(
    product.single_product?.product_container?.dispatch_information ??
      product.advanced_product?.product_container?.dispatch_information,
    defaultDispatchInformation,
    manufacturerDefaultDispatch,
  )
  // We turn on FastDelivery Badge when dispatch time is less than 10 days.
  const fastDelivery = dispatchTime <= 10 ? true : false
  // ============ NEW BADGE ========================
  const newProduct = checkIfProductIsNew(
    product.single_product?.product_container?.createdAt ??
      product.advanced_product?.product_container?.createdAt,
  )

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.badgeBox}>
        {discounted && <DiscountBadge content={discountPercentage} left />}
        {fastDelivery && <FastDeliveryBadge left />}
        {newProduct && <NewProductBadge left />}
      </div>

      <Swiper
        modules={[Navigation, Pagination]}
        tag="section"
        wrapperTag="ul"
        resistance={true}
        longSwipes={false}
        autoplay={{ delay: 5000, disableOnInteraction: true }}
        loop={images?.length === 1 ? false : true}
        pagination={images?.length === 1 ? false : true}
        className={clsx(className, classes.slider)}
      >
        {productImagesArranged.map((image) => {
          const imageLink = image.src_xs ?? image.src ?? imageFallback

          return (
            <SwiperSlide
              key={image.id}
              tag="li"
              style={{ backgroundColor: '#fff' }}
            >
              <CardMedia image={imageLink} className={classes.media} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default MobileProductPageSlider
